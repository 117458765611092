<rs-public-topbar [platform]="title"></rs-public-topbar>

<rs-loader></rs-loader>

<router-outlet [attr.data-app-version]='appVersion'></router-outlet>

<rs-footer
  [cookiesKey]="'FOOTER.COOKIES'"
  [disclaimerKey]="'FOOTER.DISCLAIMER'"
  [needHelpKey]="'FOOTER.NEED_HELP'"
></rs-footer>