import { ErrorHandler } from '@angular/core';
import { environment } from '_environments/environment';
import { SentryErrorHandler } from './sentry-error-handler';

export function errorHandlerFactory(): ErrorHandler {
  if (environment.enableSentryMonitoring) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
