import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '_app/core/core.module';
import { SharedModule } from '_shared/shared.module';
import { RsLoaderComponent } from '@rs-shared-lib/components/rs-loader/components/rs-loader.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    CoreModule,
    RsLoaderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
