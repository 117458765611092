/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    pathMatch: 'full'
  },
  {
    path: 'offer/:id',
    loadChildren: () => import('./features/offer/offer.module').then(m => m.OfferModule),
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 64]
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
