import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: Error | HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          Sentry.configureScope((scope) => {
            scope.setTag('app.error-type', 'http-error');
            scope.setExtra('error', error);
            scope.setLevel('error');
          });

          Sentry.captureMessage('http-error');
        }
        return throwError(() => error);
      })
    );
  }
}
