import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { errorHandlerFactory } from '_core/error-handlers/error-handler-factory';
import { HttpErrorsInterceptor } from '_core/interceptors/http-errors.interceptor';
import {
  ENVIRONMENT,
  RsMissingTranslationHandler,
  RsMomentDateAdapter,
  RsMultiHttpTranslateLoader,
  TranslationService
} from '@lib/rs-ngx';
import { environment } from '_environments/environment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);

@NgModule({ imports: [
  BrowserAnimationsModule,
  BrowserModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: RsMultiHttpTranslateLoader,
      deps: [HttpClient, ENVIRONMENT, 'appName'],
    },
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: RsMissingTranslationHandler,
    },
  })
],
providers: [
  { provide: 'appName', useValue: 'PRE-ORD' },
  { provide: DateAdapter, useClass: RsMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  { provide: ENVIRONMENT, useValue: environment },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
  { provide: ErrorHandler, useFactory: errorHandlerFactory },
  provideHttpClient(withInterceptorsFromDi()),
] })
export class CoreModule {

  public constructor(
        @Optional() @SkipSelf() coreModule: CoreModule,
        private readonly translateService: TranslationService,
  ) {
    if (coreModule) {
      throw new Error('CoreModule is already loaded. Import it only once!');
    }
    this.translateService.initialize();
  }
}
