<div class="public-topbar-container">
  <div class="public-topbar container">
    <div class="col-12 h-100">
      <div class="row h-100">
        <a
          class="public-topbar-logo h-100 mx-15px"
          href="https://authentication.tst.rentasolutions.org"
        >
          <img
            alt="logo"
            class="logo"
            src="https://cdn.rentasolutions.org/images/RS_logo_white_transparant.png"
          />
        </a>
      </div>
    </div>
  </div>
</div>